.purple-button {
  border-radius: 200px;
  border: 3px solid var(--Stroke-Purple, #7340D8);
  background: var(--Purple, #864BFA);
  padding: 4px 20px;
  align-self: flex-end;
  color: var(--white, #FFF);
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Mark Pro
}

.purple-button:hover {
  border: 3px solid var(--Stroke-Purple, #7340D8);
  background: var(--Stroke-Purple, #7340D8);
}

.purple-button:disabled {
  border-radius: 200px;
  border: 3px solid var(--Light-Purple, #D7C9EE);
  background: var(--Ultra-Light-Purple, #E8E1EF);
}