.base-input {
  border: 2px solid #d7c9ee;
  border-radius: 16px;
  padding: 2px 12px;
  padding-top: 4px;
  width: 100%;
  color: #482481;
  font-size: 15px;
  font-weight: 400;
}

.base-input:focus {
  border: 2px solid #d7c9ee;
  outline: none;
  border-radius: 30px;
}

.base-input::placeholder {
  color: var(--light-purple);
  font-family: Mark Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.area-codes-container {
  position: absolute;
  width: 180px;
  margin-top: 125px;
  margin-left: 43px;
  border-radius: 8px;
  background-color: white;
  font-size: 15px;
  padding:  0px;
  height: 84px; 
  overflow-y: scroll
}

@media screen and (max-width: 400px) {
  .area-codes-container {    
    width: 150px;
    font-size: 13px;
  }
}

.area-codes-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.area-codes-container::-webkit-scrollbar:vertical {
  width: 6px;
}

.area-codes-container::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.area-codes-container::-webkit-scrollbar:horizontal {
  height: 10px;
}

.area-codes-container::-webkit-scrollbar-thumb {
  background-color: var(--light-purple);
  border-radius: 10px;
  margin-left: 100px !important;
}

.area-codes-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 0px 12px;
}

.area-codes-option:hover {
  background-color: var(--ultra-light-purple);
}

.area-codes-number {
  cursor: pointer;
  font-weight: 500;
}

.area-codes-city {
  width: 80%;
  height: 22.5px;
  font-weight: 400;
  cursor: pointer;
  text-align: right;
  overflow: hidden
}