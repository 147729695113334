.white-button {
  border-radius: 200px;
  border: 3px solid #e8e1ef;
  background: #fff;
  padding: 12px 60px;
  color: #864bfa;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  font-family: Mark Pro;
}

.small {
  padding: 8px 30px;
  font-size: 14px;
}

.white-button:hover {
  border: 3px solid #e8e1ef;
  background: #e8e1ef;
}

.white-button:disabled {
  border: 3px solid #eeecea;
  background: #fff;
  color: #eeecea;
}

@media screen and (max-width: 630px) {
  .white-button {
    padding: 10px 40px;
    font-size: 16px;
  }
}
