.mobile-dropdown-button {
  background-color: #864bfa;
  color: #fff;
  border: 2px solid #864bfa;
  border-radius: 16px;
  width: 100%;
  padding: 6px 20px;
  font-size: 14px;
  text-align: center;
  font-family: Mark Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mobile-dropdown-button:hover {
  background-color: #864bfa;
  border: 2px solid #864bfa;
}

.mobile-dropdown-button:disabled {
  background-color: #d7c9ee;
  border: 2px solid #d7c9ee;
}

.mobile-dropdown-container {
  border-radius: 24px;
  border: 2px solid #d7c9ee;
  padding: 8px;
  display: inline-block;
  background-color: #ffffff;
  width: 100%;
  min-height: 428px;
}

.mobile-menu {
  display: none;
  transform: scaleY(0);
  transition: transform 0.2s ease-in-out;
}

.mobile-menu.open {
  transform: scaleY(1);
  display: block;
  height: 360px;
}

.mobile-selected-value {
  background-color: #482481;
  border: 2px solid #482481;
}
