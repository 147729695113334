.modal-interior {
  display: flex;
  justify-self: center;
  background-color: var(--white);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 130px;
  margin-top: 30vh;
  border-radius: 24px;
  max-width: 682px;
  min-height: 352px !important;
  box-shadow: inset 0 0 0 2px var(--light-purple);
  text-align: center;
  z-index: 1;
}

.modal-interior-mobile {
  display: flex;
  justify-self: center;
  background-color: var(--white);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 160px;
  margin-top: 21vh;
  border-radius: 24px;
  width: 350px;
  max-width: 350px;
  min-height: 401px !important;
  box-shadow: inset 0 0 0 2px var(--light-purple);
  text-align: center;
  z-index: 1;
}

.background-custom-modal-interior{
  position: absolute;
  bottom: -20px;
  background-color: var(--black-purple);
  width: calc(100% + 16px);
  height: 40%;
  border-radius: 22px;
  z-index: -1;
}

.title-modal-interior {
  color: var(--dark-purple);
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.arrow-down {
  width: 19px;
}

.subtitle-modal-interior {
  color: var(--dark-purple);
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subtitle-2-modal-interior {
  color: var(--dark-purple);
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-purple-kms-interior {
  background-color: var(--black-purple);
  border-radius: 200px;
  border: 3px solid #fff;
  box-shadow: 0px 2px 20px 0px rgba(24, 14, 49, 0.06);
  width: 170px;
  height: 40px;
  color: var(--white, #fff);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn-white-kms-interior {
  background-color: var(--white);
  border-radius: 200px;
  border: 3px solid var(--ultra-light-purple);
  background: var(--white, #fff);
  width: 170px;
  height: 40px;
  color: var(--purple);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.button-container {
  margin: 75px auto 50px auto;
  display: flex;
  justify-content: center;
}

.modal-footer-custom-a {
  position: fixed;
  bottom: 40px;
}

.modal-footer-custom {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  border-top: 0px;
  position: fixed;
  bottom: 40px;
  margin-left: -155px;
}

.modal-footer-custom strong:hover {
  color: #e8e1ef;
}

.btn-start-modal-derivator {
  width: 120px;
  height: 30px;
  border-radius: 200px;
  border: 3px solid var(--ultra-light-purple);
  background: var(--white);
  color: var(--purple);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
