.modal-base-auth {
  background-color: var(--white);
  border: 3px solid var(--light-purple);
  border-radius: 24px;
  background-image: url("../images/background-modal-auth.png");
  width: 100% !important;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.icon-modal-auth {
  cursor: pointer;
}

.fade.modal.show {
  background-color: rgba(24, 14, 49, 0.8);
}

@media screen and (max-width: 630px) {
  .modal-base-auth {
    background-image: url("../images/background-modal-auth-mobile.png");
    background-size: 100% 300px;
  }
  .icon-modal-auth {
    height: 20px;
    width: 20px;
  }
}

@media screen and (max-height: 670px) {
  .modal-base-auth {
    padding: 8px;
    padding-bottom: 0;
  }
}