.custom-dropdown-button {
  background-color: #864bfa;
  color: #fff;
  border: 2px solid #864bfa;
  border-radius: 16px;
  padding: 6px 6px;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
}

.custom-dropdown-button:hover {
  background-color: #864bfa;
  border: 2px solid #864bfa;
}

.custom-dropdown-button:disabled {
  background-color: #d7c9ee;
  border: 2px solid #d7c9ee;
}

.custom-dropdown-container {
  border-radius: 24px;
  border: 2px solid #d7c9ee;
  padding: 8px;
  display: inline-block;
  background-color: #ffffff;
  width: 230px;
}

.menu {
  display: none;
  transform: scaleY(0);
  transition: transform 0.2s ease-in-out;
}

.menu.open {
  transform: scaleY(1);
  display: block;
  height: 360px;
}

.selected-value {
  background-color: #482481;
  border: 2px solid #482481;
}

@keyframes desplazamientoCarrusel {
  100% {
    transform: translateX(-100%);
  }
  0% {
    transform: translateX(100%);
  }
}

.contenedor {
  overflow: hidden;
}

.textoAnimado {
  white-space: nowrap;
  display: inline-block;
  animation: desplazamientoCarrusel 10s linear infinite;
}
