.button-group-wrapper-stepper {
  display: inline-block;
  border-left: 3px solid #d7c9ee;
  border-right: 3px solid #d7c9ee;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  border-radius: 100px;
  padding: 0px 6px;
  width: 90%;
  margin: 0 20px;
}

.button-group-stepper {
  display: flex;
  border-radius: 100px;
  overflow: hidden;
  background: #e8e1ef;
}

.button-stepper {
  flex: 1;
  display: flex;
  padding: 8px;
  border-radius: 0;
  background: #e8e1ef;
  text-align: center;
  font-family: "Mark Pro", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--stroke-dark-purple);
  border: none;
  align-items: center;
  justify-content: center;
}

/* Estilo para el botón seleccionado */
.button-stepper.selected {
  border-radius: 100px;
  border: 3px solid var(--Stroke-Purple, #7340d8);
  background: var(--Purple, #864bfa);
  color: var(--white, #fff);
  line-height: normal;
  padding: 4px;
}

.mobile-component-display {
  margin: 10px;
  padding: 0 10px;
}
