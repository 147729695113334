@import "~bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

:root {
  //----------------------------- PURPLE ----------------------------
  --black-purple: #180e31;
  --black-purple-opacity: rgba(24, 14, 49, 0.2);
  --stroke-dark-purple: #321670;
  --dark-purple: #482481;
  --dark-purple-two: #5f35bc;
  --stroke-purple: #7340d8;
  --purple: #864bfa;
  --light-purple: #d7c9ee;
  --ultra-light-purple: #e8e1ef;
  --cool-gray: #c8c7cc;
  --alert: #f40000;
  --border-red: #db0000;
  --google-red: #db4437;

  //----------------------------- GRAY ----------------------------

  --light-grey: #eeeeee;

  //----------------------------- WHITE ----------------------------

  --white: #ffffff;
}

$thumb-size: 14px;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;

  &:active {
    cursor: grabbing;
  }
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.input-wrapper {
  width: calc(95% + #{$thumb-size});
  position: absolute;
  height: $thumb-size;
}

.control-wrapper {
  width: 95%;
  position: absolute;
  height: $thumb-size;
}

.input {
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  &::-ms-track {
    @include track-styles;
  }

  &::-moz-range-track {
    @include track-styles;
  }

  &:focus::-webkit-slider-runnable-track {
    @include track-styles;
  }

  &::-ms-thumb {
    @include thumb-styles;
  }

  &::-moz-range-thumb {
    @include thumb-styles;
  }

  &::-webkit-slider-thumb {
    @include thumb-styles;
  }
}

.rail {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  border-radius: 3px;
  background: var(--ultra-light-purple);
}

.inner-rail {
  position: absolute;
  height: 4px;
  background: var(--purple);
}

.control {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  background: var(--dark-purple);
  top: 50%;
  margin-left: calc(#{10px} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
  border-radius: 14px;
  border: 1.5px solid var(--white, #fff);
}

.divider-all {
  height: 2px;
  background: var(--light-purple);
  border-radius: 1px;
}
