@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  font-family: "Mark Pro", Arial, Verdana, sans-serif;
  margin: 0;
}

p {
  font-family: "Mark Pro";
  margin-bottom: 0px !important;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.p-all {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.container-app-all{
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 770px) {
  .p-all {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media screen and (min-width: 1600px) {
  .container-all {
    /* margin-left: -200px;
    margin-right: -200px; */
  }
}

@media screen and (max-width: 1600px) {
  .card-cars {
    width: 305px !important;
  }
  .card-filter {
    width: 290px !important;
  }
  .text-characteristics {
    font-size: 8px !important;
  }
}

@media screen and (max-width: 1200px) {
  .card-filter {
    width: 260px !important;
  }
}

@media screen and (max-width: 768px) {
  .card-cars {
    width: 340px !important;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-inner {
  display: flex;
  gap: 15px;
}

.carousel-sale > .carousel-inner{
  display: flex;
  gap: 15px;
}

.text-animated {
  color: #482481;
  text-align: center;
  font-family: Mark Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}

#animated-text-strip {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
}
#animated-text-strip .text-animated {
  white-space: nowrap;
  max-width: none;
  animation-play-state: paused;
}

#animated-text-strip:hover .text-animated {
  animation: text-animated 6s linear infinite;
}

@keyframes text-animated {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.text-option-mobile {
  color: #482481;
  text-align: center;
  font-family: Mark Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-option-mobile {
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: auto;
  text-overflow: initial;
  cursor: pointer;
}

/* Optional: Add styles for the scrollbar */
.text-option-mobile::-webkit-scrollbar {
  height: 6px;
}

.text-option-mobile::-webkit-scrollbar-thumb {
  background-color: var(--dark-purple-two);
  border-radius: 10px;
}

.text-option-mobile::-webkit-scrollbar-track {
  background-color: var(--light-purple);
  border-radius: 10px;
}

.none-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.base-input {
  border: 2px solid #d7c9ee;
  border-radius: 30px;
  padding: 2px 12px;
  width: 100%;
  color: #482481;
  font-size: 16px;
}

.base-input:focus {
  border: 2px solid #d7c9ee;
  outline: none;
  border-radius: 30px;
}

.base-input::placeholder {
  color: var(--light-purple);
  font-family: Mark Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


@media screen and (max-width: 600px) {
  .container-app-all {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: none !important;
    padding: 0px !important;
  }
}