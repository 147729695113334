.modal-kms-low {
  border-radius: 16px !important;
  background-image: url("../../images/background-modal-purple.png");
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
}

.title-modal-low-kms {
  color: var(--purple);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.value-kms-modal {
  color: var(--purple);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ask-kms-modal {
  color: var(--purple);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-purple-kms-modal {
  background-color: var(--purple);
  border-radius: 200px;
  border: 3px solid #fff;
  box-shadow: 0px 2px 20px 0px rgba(24, 14, 49, 0.06);
  width: 170px;
  height: 40px;
  color: var(--white, #fff);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.btn-white-kms-modal {
  background-color: var(--white);
  border-radius: 200px;
  border: 3px solid var(--ultra-light-purple);
  background: var(--white, #fff);
  width: 170px;
  height: 40px;
  color: var(--purple);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 430px) {
  .modal-kms-low {
    background-image: url("../../images/background-modal-purple-mobile.png");
    background-size: 100% 150px;
  }

  .title-modal-low-kms {
    font-size: 22px;
  }
  .value-kms-modal {
    font-size: 16px;
  }

  .btn-white-kms-modal {
    margin-bottom: 10px;
  }
}
