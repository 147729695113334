.dropdown-item-container {
  width: 100%;
  background-color: #ffffff;
  height: 350px;
  overflow: scroll;
  overflow-x: hidden;
}

.dropdown-item-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.dropdown-item-container::-webkit-scrollbar:vertical {
  width: 6px;
}

.dropdown-item-container::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.dropdown-item-container::-webkit-scrollbar:horizontal {
  height: 10px;
}

.dropdown-item-container::-webkit-scrollbar-thumb {
  background-color: var(--dark-purple-two);
  border-radius: 10px;
  margin-left: 100px !important;
}

.dropdown-item-container::-webkit-scrollbar-track {
  background-color: var(--light-purple);
  border-radius: 10px;
}

.dropdown-item {
  color: #482481;
  text-align: center;
  font-family: Mark Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-item-divider {
  width: 100%;
  height: 2px;
  background-color: #e8e1ef;
  border-radius: 20px;
}
